import { getDeviceDimensions, getPlaceholderDeviceDimensions, getProjectFromChassis } from "../model/ChassisProject";
import { getFamilyFromPlatform } from "../platforms/PlatformIDs";
import { BOMItemType } from "../summary/SummaryHelp";
import { Chassis } from "../types/ProjectTypes";
import { BOMItemExch, ChassisPropertiesExch, ChassisPropHeaderExch } from "../userProject/UserProjectTypes";


export const addChassisPropMainHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis) => {
    const mainHeader: ChassisPropHeaderExch = {
        headerName: 'General Chassis Information',
        items: [],
        headers: [],
    }

    const project = getProjectFromChassis(chassis);

    // Add the items...
    if (project && project.config.projectName) {
        mainHeader.items.push(
            {
                name: 'Configuration Name',
                value: project.config.projectName
            }
        );
    }

    if (chassis.name) {
        mainHeader.items.push(
            {
                name: 'Chassis Name',
                value: chassis.name
            }
        );
    }

    mainHeader.items.push(
        {
            name: 'Family',
            value: getFamilyFromPlatform(chassis.platform)
        }
    );

    mainHeader.items.push(
        {
            name: 'Platform',
            value: chassis.platform
        }
    );

    // Add the Main Header to the props
    chassisProp.headers.push(mainHeader);
}

export const addChassisPropSizeHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis, __bankNumber: number = 0) => {
    const sizeHeader: ChassisPropHeaderExch = {
        headerName: 'Chassis Size',
        items: [],
        headers: [],
    }

    // Add the items...
    sizeHeader.items.push(
        {
            name: '# of Slots/Modules',
            value: `${chassis.modules.length}`
        }
    );

    const chassisDims = chassis.isPlaceholder
        ? getPlaceholderDeviceDimensions(chassis)
        : getDeviceDimensions(chassis);

    sizeHeader.items.push(
        {
            name: 'Height',
            value: `${chassisDims.height} mm`
        }
    );

    sizeHeader.items.push(
        {
            name: 'Width',
            value: `${chassisDims.width} mm`
        }
    );

    sizeHeader.items.push(
        {
            name: 'Depth',
            value: `${chassisDims.depth} mm`
        }
    );

    // Add the Main Header to the props
    chassisProp.headers.push(sizeHeader);
}


export const addChassisPropPartsHeader = (chassisProp: ChassisPropertiesExch, chassis: Chassis, bomItemEx?: BOMItemExch) => {
    const partsHeader: ChassisPropHeaderExch = {
        headerName: 'Part List',
        items: [],
        headers: [],
    }

    // Note: We could start iterating the children here, but we 
    // are NOT adding any more main headers under the 'partsHeader'.
    // Pass the bomItemEx to addChassisPropBOMItem if we have it.
    if (bomItemEx)
        addChassisPropBOMItem(bomItemEx, partsHeader);

    chassisProp.headers.push(partsHeader);
}

const addChassisPropBOMItem = (item: BOMItemExch, parentHeader: ChassisPropHeaderExch) => {
    // If we have a product...
    if (item.templateTitle === BOMItemType.Product) {
        const itemHeader: ChassisPropHeaderExch = {
            headerName: item.name,
            catalogNumber: item.name,
            items: [],
            headers: [],
        };


        if (item.description.length) {
            itemHeader.items.push(
                {
                    name: 'Description',
                    value: `${item.description}`
                }
            );
        }

        itemHeader.items.push(
            {
                name: 'Quantity',
                value: `${item.quantity}`
            }
        );

        parentHeader.headers.push(itemHeader);
    }

    // If the item (Product/Header/?) has children...
    if (item.children) {
        // Recurse - we are only adding products.
        item.children.forEach(child => {
            addChassisPropBOMItem(child, parentHeader);
        });
    }
}
