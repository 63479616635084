import React from 'react';
import './FloatingDetails.scss';
import '../../styles/Meteor.scss'
import { Chassis } from '../../types/ProjectTypes';
import { PlatformCLX } from "../../platforms/PlatformConstants";
import {
    addDetailItem,
    DetailGroup,
    DetailGrpType,
    makeDetailGroup,
    makeDimensionsGrp
} from '../../model/DeviceDetail';
import DetailsGroup from './DetailsGroup';
import { chassisHasController, getDeviceDimensions, getEnvRatingFromSpec, getPlaceholderDeviceDimensions } from '../../model/ChassisProject';

const getGenInfoGroup = (chassis: Chassis): DetailGroup => {
    const envRating =
        getEnvRatingFromSpec(chassis.extendedTemp, chassis.conformal);
    const highAvail =
        (chassis.redundant && chassisHasController(chassis));

    const genGrp = makeDetailGroup(DetailGrpType.Group);
    addDetailItem(genGrp, 'Environmental Rating', envRating.toString());

    // High avail controller (redundant) applicable only to CLX.
    if (chassis.platform === PlatformCLX) {
        addDetailItem(genGrp, 'High Availability Controller', highAvail ? 'Yes' : 'No');
    }

    return genGrp;
}


interface Props {
    chassis: Chassis;
}

const DetailsChassisSpecs = (props: Props) => {

    // Get applicable dims. Note that placeholder
    // version now responsible for adding right
    // end cap width, if the platform has one.
    const chassisDims = props.chassis.isPlaceholder
        ? getPlaceholderDeviceDimensions(props.chassis)
        : getDeviceDimensions(props.chassis);

    const dimGrp = makeDimensionsGrp(chassisDims); 

    const genGrp = getGenInfoGroup(props.chassis);
    return (
        <div className='floating-details-tab'>
            <DetailsGroup group={dimGrp} />
            <DetailsGroup group={genGrp} />
        </div>
    );
}

export default DetailsChassisSpecs;
