import { isSupportedPlatform } from "../platforms/PlatformIDs";
import { DragDeviceInfo, DropResult, DropStatus } from "../util/DragAndDropHelp";
import { IdxRange, LocAndSize, NormRect, Point } from "../types/SizeAndPosTypes";
import {  PlatformFlexHA, PlatformMicro } from "../platforms/PlatformConstants"
import { ChoicesGroup } from '../types/MessageTypes';
import {
    Chassis,
    ChassisElement,
    ChassisModule,
    ChassisProject,
    ChassisProjectConfig,
    DeviceCategory,
    GraphicalDevice,
    HardwareBuilder,
    NO_SLOT,
    Rack,
    RackGroup,
    SlotIDError,
    EnvRating,
    IOModuleWiring,
    SupportedWiringType,
    SelectableDevice,
    BasicProdInfo,
    LocAttributeInfo,
    ChassisPowerSupply,
    DeviceType,
    AuxBank,
    BankInfo,
    MicroChassis
} from "../types/ProjectTypes";
import { IOEntryModeEnum } from "../types/SettingsTypes";
import {
    bringLocIntoView,
    ChassisLayoutKey,
    getStageSpacingDtls,
} from "../util/LayoutHelp";
import { getEmptyPowerBreakdown, isEmptyPower, subtractPowerBreakdown } from "../util/PowerHelp";
import { PowerBreakdown } from "../types/PowerTypes";
import { getNewProjectLog, LogMsgLevel, StatusLogType } from "../util/ProjectLog";
import {
    getRedChassisLoc,
    getRedDepictOption,
    getRedSecDistBelowPri,
    getRedSecDistRightOfPri,
    getRedBelowGap,
    getRedBesideGap,
    RedDepictOption
} from "../util/RedChassisHelp";
import { contentChanging } from "../util/UndoRedo";
import {
    getNormRect,
    pointInRect,
    samePoints
} from "../util/GeneralHelpers";
import { LayoutModeType, LayoutMode } from '../util/LayoutModeHelp';
import {getCurrentLocIOWiringForApp, getCurrentPowerSupplyValueForApp} from "./GuidedSelection";
import {
    EngInfoCommModule,
    EngInfoComponent,
    EngInfoController,
    EngInfoFPDModule,
    EngInfoModule,
    EngInfoPowerSupply
} from "../engData/EngineeringInfo";
import {
    getBasicEngInfoFor,
    getEngInfoForComp,
    getModuleEngInfo,
    getPowerSupplyEngInfo
} from "../util/EngInfoHelp";
import { addRequiredAccys } from "../util/AccessoriesHelp";
import { Dimensions } from "../util/DimensionHelp";
import { DEFAULT_IMAGE_LOCATION } from "../types/Globals";
import {
    addModuleAtSlot,
    addModuleToChassis,
    canExtendChassis,
    createChassis,
    deleteModuleAtSlot,
    dropDragDeviceOnChassis,
    getChassisDropStatus,
    getChassisElementAtPt,
    getChassisNameSelection,
    getChassisSizeAsDrawn,
    getDefaultChassisName,
    getMaxNewModules,
    getModuleSlotRestriction,
    getNumSlots,
    getSlotFillerInfo,
    getSlotID,
    getXSlotWidthFor,
    updateChassis
} from "../implementation/ImplGeneral";
import {
    addLocationAttrInfo,
    getAvailableLocAttrInfoIDs,
    getLocationAttrInfo,
    updateLocAttrInfo
} from "./LocAttributeInfo";
import { getNewInstanceId } from "../util/InstanceIdHelp";
import { areUndoSnapshotsSuspended } from "../util/UndoRedo";
import { projItemNamesInUse, setConfigModified } from "../userProject/UserProjectHelp";
import { logger } from "../util/Logger";
import { StartupDetails } from "../util/AppStartupHelp";
import { PlatformCLX, PlatformCpLX, PlatformFlex } from "../platforms/PlatformConstants";
import { snapCompactModuleArray } from "../platforms/snap/SnapGeneralImpl";


const _getUniqueConfigName = (platform: string): string => {
    const name = platform + ' Cfg';
    if (projItemNamesInUse.has(name)) {
        for (let num = 2; num < 500; num++) {
            const altName = name + ' ' + num;
            if (!projItemNamesInUse.has(altName)) {
                return altName;
            }
        }
    }
    return name;
}


export const createConfigForChassisProj = (
    platform: string,
    installLoc = '',
    industry = '',
    entryMode = IOEntryModeEnum.Basic
): ChassisProjectConfig => {

    logger.logStartup('Startup: createConfigForProj');

    const config: ChassisProjectConfig = {
        projectName: _getUniqueConfigName(platform),
        installLocID: installLoc,
        industryID: industry,
        IOEntryMode: entryMode,
        currLocAttrID: '',
        arrLocAttr: [],
    };

    // Check if we have any locations created
    // from the startup-details (we should!)...
    const LocIDs = getAvailableLocAttrInfoIDs();
    if (LocIDs.length === 0) {
        logger.logStartup('Default location not created from startup details!!!');
        config.currLocAttrID = addLocationAttrInfo(
            platform,
            config.industryID,
            config.installLocID,
            config.IOEntryMode
        );

        // Load Guided Selection, etc.
        updateLocAttrInfo(config.currLocAttrID, onDefaultGuidedSelectionLoaded);
    }
    else {
        config.currLocAttrID = LocIDs[0];
    }   

    return config;
}

const onDefaultGuidedSelectionLoaded = (success: boolean) => {
    if (success)
        return;

    throw new Error('onGuidedSelectionLoaded(): Failed!');
}

export const resetHardwareBuilder = (hw: HardwareBuilder) => {
    hw.catChassis = undefined;
    hw.catController = undefined;
    hw.catControllerChassis = undefined;
    hw.catControllerPartner = undefined;
    hw.catPowerSupply = undefined;
    hw.catPwrSupCtrlChassis = undefined;
    hw.redCtrlChassis = undefined;
    hw.catScanner = undefined;
    hw.catSlotFiller = undefined;
    hw.ctrlChassisNeedsScanner = false;
    hw.ctrlDedicatedChassis = false;
    hw.numChassisSlot = undefined;
    hw.numCtrlChassisSlot = undefined;
    hw.remoteIOOnly = undefined;
    hw.mapErrMessages.clear();
    hw.catIOModules.length = 0;
    hw.ioModuleCount = 0;
    hw.ioModuleSelections.length = 0;
}

export const createEmptyRackGroup = (): RackGroup => {
    return {
        racks: new Array<Rack>(),
        totalExtent: { x: 0, y: 0 },
        parent: undefined,
        newContent: false,
        anyRedundant: false,

        // 2023.9.12 The RackGroup now contains the Project Status Log.
        statusLog: getNewProjectLog(StatusLogType.project),
    };
}

export const getProductDescription = (platform: string, catNo: string): string => {
    // Get BASIC engineering info here. The
    // incoming catNo might be an accessory.
    const basicInfo = getBasicEngInfoFor(platform, catNo);
    if (basicInfo) {
        return basicInfo.description;
    }
    return '<description not available>';
}

export const createProject = (startupDtls: StartupDetails): ChassisProject => {

    // Sanity check that any platform we ARE 
    // given is one that we actually support.
    if (!isSupportedPlatform(startupDtls.platform)) {
        throw new Error('Unsupported platform in createProject: ' + startupDtls.platform);
    }

    // Create the project object. 
    const proj: ChassisProject = {
        id: getNewInstanceId(),
        config: createConfigForChassisProj(
            startupDtls.platform,
            startupDtls.country,
            startupDtls.industryID),
        content: createEmptyRackGroup(),
    };

    // Hook content parent back to project.
    proj.content.parent = proj;

    // Update the rack layout, which really just sets
    // the content extent, since we have no racks.
    // Note that we set the optional setModified arg
    // to false. This is a brand new project that
    // has NOT yet been modified.
    updateRackLayout(proj.content, false);

    // Return the project.
    return proj;
}

export const getLocAttrFromProject = (project: ChassisProject): LocAttributeInfo => {
    const loc = getLocationAttrInfo(project.config.currLocAttrID);
    if (loc) {
        return loc;
    }

    throw new Error('ERROR - getLocationSettings called on incompete project!');
}


export const addChassis = (
    project: ChassisProject,
    platform: string,
    catNo: string,
    insertAt = -1,
    psCatNo = '',
    buCatNo = ''
): Chassis | null => {
    const chassis = createChassis(platform, catNo, psCatNo, buCatNo);
    if (chassis) {
        contentChanging(project.content);
        chassis.parent = project.content;
        chassis.defaultIOModWiring = getCurrentLocIOWiringForApp(project);
        const microChassis = chassis as MicroChassis;
        microChassis.defaultPowerSupplyValue = getCurrentPowerSupplyValueForApp(project);
        addRack(project.content, chassis, insertAt);
        addRequiredAccys(chassis);
        updateRackLayout(project.content);
        return chassis;
    }
    else {
        return null;
    }
}

export const getNumRacks = (content: RackGroup): number => {
    return content.racks.length;
}

export const getLastRack = (content: RackGroup): Rack | null => {
    const lastIdx = getNumRacks(content) - 1;
    return (lastIdx >= 0) ? content.racks[lastIdx] : null;
}


// Get the 'effective' width used by the chassis, INCLUDING
// any width extension required to show its redundant depiction,
// and/or any temporary X-slot extensions used with snap-type
// chassis when the chassis is selected or a drag target.
// This ALSO includes the label used in the layout, if that
// extends to the right of depiction of the chassis itself.
// The effective width here is essentially the extent from
// the LEFT side of the chassis, and does NOT include any
// left margin used in the layout itself.
export const getEffectiveWidthInLayout = (chassis: Chassis, copyMode: boolean): number => {

    // Start with the width of what we would currently
    // see of the hardware, including any temporary
    // visual extensions (X-slots), but NOT including
    // the label.
    const szAsDrawn = getChassisSizeAsDrawn(chassis, copyMode);
    let hardwareWidth = szAsDrawn.width;

    // The left side of the label is always
    // at a fixed offset from the left side
    // of the chassis.
    let labelLeft = getStageSpacingDtls().leftIconGap;

    // If the chassis is redundant...
    if (chassis.redundant) {

        // Then adjust our numbers accordingly.
        // For the label, we're ONLY interested
        // in the RIGHTMOST use.
        switch (getRedDepictOption()) {

            case RedDepictOption.Behind:
                // Hardware depiction gets a bit wider.
                // Label left doesn't change.
                hardwareWidth += getRedSecDistRightOfPri();
                break;

            case RedDepictOption.Beside:
                {
                    const besideGap = getRedBesideGap();
                    // Here, the label we're interested in is
                    // for the RIGHT (redundant) depicted chassis.
                    labelLeft += (hardwareWidth + besideGap);

                    // Hardware width is two chassis widths
                    // plus the horizontal gap between them.
                    hardwareWidth = hardwareWidth * 2 + besideGap;
                }
                break;

            case RedDepictOption.Below:
                // No changes to width or label left pos.
                break;
        }
    }

    // Get the chassis label that will be used in the layout.
    const labelText = getChassisLabelText(chassis, chassis.redundant);

    // And the number of chars essentially guaranteed
    // to fit inside of the chassis' width without
    // extending any to the right.
    const lenThreshhold = getLabelCharsBoundary(chassis);

    // If our label's length exceeds that threshold,
    // set the right side of our label to approx position,
    // based on number of chars and average width per.
    // If not, we'll just set to 0.
    let lblRight = 0;
    if (labelText.length > lenThreshhold) {
        lblRight = labelLeft +
            (labelText.length * getStageSpacingDtls().chassisLblCharWidth);
    }

    // Our final effect width (extent) is the max of the
    // width of the hardware depiction, or the right side
    // of the label.
    return Math.max(hardwareWidth, lblRight);
}

// Note: this function expects that all rack positions
// have ALREADY been established. It's purpose is ONLY
// to calculate and reset the overall extent of the content.
// Note also that calling this function directly DOES NOT
// result in a setContentModified call.
export const updateContentExtent = (content: RackGroup, copyMode: boolean) => {

    //logger.log('updateContentExtent');

    const spacingDtls = getStageSpacingDtls(ChassisLayoutKey);

    // Find the widest chassis (as depicted). Init to 0.
    let maxWidth = 0;

    // For each rack...
    for (let rackIdx = 0; rackIdx < content.racks.length; rackIdx++) {

        // Get the rack.
        const rack = content.racks[rackIdx];

        // Compare and use this one's EFFECTIVE width as
        // our new max IF wider than what we've seen so far.
        // Effective width includes as-drawn hardware and label.
        maxWidth = Math.max(
            maxWidth,
            getEffectiveWidthInLayout(rack.chassis, copyMode)
        );
    }

    // Start yExtent at our top margin.
    let yExtent = spacingDtls.topMargin;

    // If we HAVE any racks...
    if (content.racks.length > 0) {

        // Get the LAST one.
        const lastRack = content.racks[content.racks.length - 1];

        // Set yExtent to its bottom y location.
        yExtent = lastRack.ptOrg.y + lastRack.chassis.layout.size.height;

        // If this chassis is redundant...
        if (lastRack.chassis.redundant) {

            // Add extra vertical usage if/as needed for the
            // redundant depiction option currently in effect.
            switch (getRedDepictOption()) {
                case RedDepictOption.Behind:
                    yExtent += getRedSecDistBelowPri();
                    break;

                case RedDepictOption.Below:
                    yExtent += (lastRack.chassis.layout.size.height + getRedBelowGap());
                    break;

                default:
                    break;
            }
        }
    }

    // Finally, set total extent for our content overall.
    content.totalExtent.x = spacingDtls.leftMargin + maxWidth + spacingDtls.rightMargin;
    content.totalExtent.y = yExtent + spacingDtls.bottomMargin;
}

export const updateProjContentExtent = (project: ChassisProject, copyMode: boolean) => {
    updateContentExtent(project.content, copyMode);
}

// The primary work of this function is to LOCATE
// each rack in the content provided. We now call
// a separate function (updateContentExtent above)
// to calculate and set the overall extents.
export const updateRackLayout = (content: RackGroup, setModified = true) => {

    // NO-OP if undo snapshots are suspended.
    if (areUndoSnapshotsSuspended()) {
        return;
    }

    const spacingDtls = getStageSpacingDtls(ChassisLayoutKey);

    // Reset our .anyRedundant flag.
    content.anyRedundant = false;

    // Start a point using upper left margins.
    // This will be the org pt of the first (top) chassis.
    const pt: Point = { x: spacingDtls.leftMargin, y: spacingDtls.topMargin };

    // For each rack...
    for (let rackIdx = 0; rackIdx < content.racks.length; rackIdx++) {

        // If not the first, add vertical sep space
        // to our running point.
        if (rackIdx > 0) {
            pt.y += spacingDtls.verticalSep;
        }

        // Get the rack.
        const rack = content.racks[rackIdx];

        // Note: our rack's ptOrg point is used as a property
        // for some components that are memo-ized. As such, when
        // its content (x or y) changes, we want a NEW object.
        // When its content is NOT changing, we want to leave
        // the OLD object in place. Otherwise, every component
        // that gets it as a prop will see that it CHANGED,
        // resulting in a re-render that might not otherwise
        // be required.

        // Set the rack's org to match our current point, but ONLY
        // if they don't already have the same value content.
        if (!samePoints(rack.ptOrg, pt)) {
            rack.ptOrg = { ...pt };
        }

        // Add the rack's height to our pt's y.
        pt.y += rack.chassis.layout.size.height;

        // If this chassis is redundant...
        if (rack.chassis.redundant) {

            // Set the flag accordingly in our content.
            content.anyRedundant = true;

            // Add extra vertical usage if/as needed for the
            // redundant depiction option currently in effect.
            switch (getRedDepictOption()) {
                case RedDepictOption.Behind:
                    pt.y += getRedSecDistBelowPri();
                    break;

                case RedDepictOption.Below:
                    pt.y += (rack.chassis.layout.size.height + getRedBelowGap());
                    break;

                default:
                    break;
            }
        }
    }

    // Finally, call a helper to determine and set
    // overall extent. Note that here, our update
    // assumes we're NOT in copy mode. If an extent
    // needs to be updated while we ARE in copy mode,
    // the requirer needs to make his OWN call.
    updateContentExtent(content, false);

    if (setModified) {
        setConfigModified(true);
    }
}

export const getOrgPtForNextRack = (content: RackGroup): Point => {
    const spacingDtls = getStageSpacingDtls(ChassisLayoutKey);
    const ptOrg: Point = { x: spacingDtls.leftMargin, y: spacingDtls.topMargin };

    const lastRack = getLastRack(content);
    if (lastRack) {
        ptOrg.y = lastRack.ptOrg.y +
            lastRack.chassis.layout.size.height +
            spacingDtls.verticalSep;
    }

    return ptOrg;
}

export const addRack = (content: RackGroup, chassis: Chassis, insertAt: number) => {
    const numExst = content.racks.length;
    const isInsert = ((insertAt >= 0) && (insertAt < numExst));
    //const ptOrg = isInsert
    //    ? content.racks[insertAt].ptOrg
    //    : getOrgPtForNextRack(content);

    const newRack: Rack = { chassis: chassis, ptOrg: { x: 0, y: 0 } };

    if (isInsert) {
        content.racks.splice(insertAt, 0, newRack);
    //    const yShift = ptOrg.y + chassis.layout.size.height + ContentSpacing.verticalSep;
    //    for (let rackIdx = insertAt + 1; rackIdx < content.racks.length; rackIdx++) {
    //        content.racks[rackIdx].ptOrg.y += yShift;
    //    }
    }
    else {
        content.racks.push(newRack);
    }
}

export const isValidSlotNumber = (chassis: Chassis, slotNum: number): boolean => {
    return ((slotNum >= 0) && (slotNum < chassis.modules.length));
}

export const getModuleInSlot = (chassis: Chassis, slotNum: number): ChassisModule | undefined => {
    if (isValidSlotNumber(chassis, slotNum)) {
        return chassis.modules[slotNum];
    }
    return undefined;
    //return GetPlatformImpl(chassis.platform).getModuleInSlot(chassis, slotNum);
}

export const resetDropTargets = (content: RackGroup) => {
    content.racks.forEach(rack => {
        rack.chassis.dragTarget = false;
        rack.chassis.xSlotWidth = 0;
    });

    // Call a helper to update our overall extent.
    // Chassis depiction widths can change when
    // they're tagged as drag targets. Resetting
    // has the opposite effect.
    updateContentExtent(content, false);
}

export const tagDropTargets = (content: RackGroup, dragMod: ChassisModule) => {

    // Get eng info.
    const modInfo = getEngineeringInfoFor(dragMod);

    // If we can...
    if (modInfo) {
        // Get restriction info for the module, if any.
        const restrict = getModuleSlotRestriction(modInfo);

        // Get the POSSIBLE xSlot width that might be used
        // for the mod being dragged. If we get a positive
        // value back, it means that the mod in question COULD
        // be dropped onto an x-slot for this platform. It
        // DOES NOT necessarily mean any give chassis actually
        // has ROOM for another slot.
        const xSlotWidthForMod = getXSlotWidthFor(modInfo);

        // Walk all racks/chassis. For each...
        content.racks.forEach(rack => {

            // Platform must match. If so...
            if (rack.chassis.platform === dragMod.platform) {

                const xSlotWidth =
                    ((xSlotWidthForMod > 0) && canExtendChassis(rack.chassis))
                    ? xSlotWidthForMod
                    : 0;

                // If this is drag module's current chassis,
                // the chassis will ALWAYS be a possible drop
                // target, even if the ONLY possible drop is
                // to the same slot.
                if (dragMod.parent === rack.chassis) {
                    rack.chassis.dragTarget = true;
                    rack.chassis.xSlotWidth = xSlotWidth;
                }
                else {
                    // Different chassis. Drag target if the chassis
                    // could take a module with the given restriction.
                    // Note: getMaxNewModules() will NOT treat slot
                    // fillers as empty slots (3rd param false).
                    if (getMaxNewModules(rack.chassis, restrict, false) > 0) {
                        rack.chassis.dragTarget = true;
                        rack.chassis.xSlotWidth = xSlotWidth;
                    }
                }
            }
        });

        // Call a helper to update our overall extent.
        // Chassis depiction widths can change when 
        // they're tagged as drag targets.
        updateContentExtent(content, false);
    }
    else {
        throw new Error('Unexpected ERROR in tagDragTargets');
    }
}


export const getContentAtPoint = (content: RackGroup, pt: Point):
    [
        chassis: Chassis | null,
        element: ChassisElement,
        slotOrBank: number,
        rightSide: boolean // Applicable only for slots.
    ] => {

    // Then for each rack we have have...
    for (let chIdx = 0; chIdx < content.racks.length; chIdx++) {

        // Get the rack;
        const rack = content.racks[chIdx];

        // Get the current as-drawn size of the chassis, which CAN
        // be different than the actual size while dragging.
        const chSize = getChassisSizeAsDrawn(rack.chassis, false);

        // Get the rect containing the chassis.
        const chasRect = getNormRect(rack.ptOrg.x, rack.ptOrg.y,
            chSize.width, chSize.height);

        // If our point is somewhere in that rect...
        if (pointInRect(pt, chasRect)) {

            // Get the point as a relative offset from
            // the chassis origin (upper-left) point.
            const ptRelToChassis: Point = { x: pt.x - rack.ptOrg.x, y: pt.y - rack.ptOrg.y };

            // Call another helper to see what we're 
            // pointing at in the chassis itself.
            const [element, slotOrBank, rightSide] = getChassisElementAtPt(rack.chassis, ptRelToChassis);

            // If anything, return what we got.
            if (element !== ChassisElement.None) {
                return [rack.chassis, element, slotOrBank, rightSide];
            }
        }
        else {
            // Else if this chassis is redundant...
            if (rack.chassis.redundant) {

                // Get our option for depicting those.
                const redDepictOpt = getRedDepictOption();

                // Determine the origin point of the redundant depiction.
                const ptRedOrg = getRedChassisLoc(rack.chassis, rack.ptOrg, redDepictOpt);

                // Use that to get the rect containing it.
                const redRect = getNormRect(ptRedOrg.x, ptRedOrg.y, chSize.width, chSize.height);

                // If we're pointing at THAT, return the
                // special element type.
                if (pointInRect(pt, redRect)) {
                    return [rack.chassis, ChassisElement.RedChassis, NO_SLOT, false];
                }
            }
        }
    }

    // Not pointing at anything chassis-related.
    return [null, ChassisElement.None, NO_SLOT, false];
}

export const getDropStatusForDrag = (
    content: RackGroup,
    dragInfo: DragDeviceInfo
): DropStatus => {
    const [chassis, element, slotOrBank, rightSide] =
        getContentAtPoint(content, dragInfo.ptCtr);

    dragInfo.rightSide = rightSide;
    if (chassis) {
        return getChassisDropStatus(chassis, dragInfo, element, slotOrBank);
    }
    else {
        return DropStatus.NoDrop;
    }
}

enum AccyWorkType {
    DoNothing = 'DoNothing',
    Init = 'Init',
    Copy = 'Copy'
}

const _dropDragDeviceOnChassis = (
    chassis: Chassis,
    dragInfo: DragDeviceInfo,
    touchEl: ChassisElement,
    slot: number
): DropResult => {
    const dropRslt = dropDragDeviceOnChassis(chassis, dragInfo,
        touchEl, slot);

    // If we successfully dropped a module.
    if (dropRslt === DropResult.DropSuccess) {

        // Start with the assumption that we'll be
        // freshly initializing the dropped module's
        // accessories like we do with most other adds.
        let accyWork: AccyWorkType = AccyWorkType.Init;

        // If this was a copy (regardless of whether
        // a swap was done in the process)...
        if (dragInfo.copy) {
            // Then the NEW module will get a
            // copy the original's accessories.
            accyWork = AccyWorkType.Copy;
        }
        else {
            // We did a move, not a copy.
            // If a swap was used...
            if (dragInfo.swapToCatNo) {
                // Then we STILL created a new
                // module (with a different id).
                // Here, we'll also copy the original's
                // accessories to the new module.
                accyWork = AccyWorkType.Copy;
            }
            else {
                // Simple move without a swap. We'll
                // just leave the original module's
                // accessories alone in this case.
                accyWork = AccyWorkType.DoNothing;
            }
        }

        // If we have to do ANYTHING with accessories...
        if (!dragInfo.skipAccys &&
            (accyWork !== AccyWorkType.DoNothing)) {

            // Get the new module that was dropped.
            const newMod = chassis.modules[slot];

            // We SHOULD be able to. If we can...
            if (newMod) {
                // Then either perform a copy from
                // the old module to the new...
                if (accyWork === AccyWorkType.Copy) {
                    copyAccys(dragInfo.dragMod, newMod);
                }
                else {
                    // or an accessory init on the new module.
                    addRequiredAccys(newMod);
                }
            }
            else {
                throw new Error('Unexpected ERROR in dropDragDeviceOnChassis!');
            }
        }

        updateChassis(chassis);

        // do ONLY in swapto cases!!!
        //dragInfo.module = null;
    }

    return dropRslt;
}

export const dropDragDevice = (
    project: ChassisProject,
    dragInfo: DragDeviceInfo
): DropResult => {
    const [chassis, element, slot, rightSide] =
        getContentAtPoint(project.content, dragInfo.ptCtr);
    dragInfo.rightSide = rightSide;
    if (chassis) {
        return _dropDragDeviceOnChassis(chassis, dragInfo, element, slot);
    }
    return DropResult.DropFailed;
}


// NOTE: This function now deals with cases where a module occupies
// TWO slots. For any such modules found, the right-side slot used
// will NOT be included in EITHER of the return arrays.
export const getModuleSlotBreakdown = (
    modArr: Array<ChassisModule | undefined>,
    minIdx = -1,
    maxIdx = -1
):
    [modSlots: number[], emptySlots: number[]] => {

    const modSlots = new Array<number>();
    const emptySlots = new Array<number>();

    const firstIdx = (minIdx >= 0) ? minIdx : 0;
    const lastIdx = (maxIdx >= 0) ? maxIdx : modArr.length - 1;

    // For each entry in the module array provided...
    for (let arrIdx = firstIdx; arrIdx <= lastIdx; arrIdx++) {

        // Get the module (if there is one).
        const mod = modArr[arrIdx];

        // If there is...
        if (mod) {
            // This one's a mod slot.
            modSlots.push(arrIdx);

            if (mod.slotsUsed === 2) {
                arrIdx += 1;
            }
        }
        else {
            // This one's a (true) empty slot.
            emptySlots.push(arrIdx);
        }
    }

    // Return the two arrays.
    return [modSlots, emptySlots];
}

export const getAllChoiceIDs = (group: ChoicesGroup): string[] => {
    const ids = new Array<string>();

    group.subgroups.forEach(subgroup => {
        subgroup.selectionInfo.forEach(choice => {
            ids.push(choice.id);
        })
    });

    return ids;
}

// CAUTION: copy function WILL make sure that dest's accys match
// source's. If dest ALREADY had any accys, those will be LOST,
// and replaced with whatever source has.
export const copyAccys = (source: GraphicalDevice, dest: GraphicalDevice) => {
    if (source.accys) {
        dest.accys = new Array<string>();
        source.accys.forEach(accy => {
            dest.accys?.push(accy);
        })
    }
    else {
        dest.accys = undefined;
    }
}

const _dupBankInfo = (info: BankInfo): BankInfo => {
    return {
        bankNum: info.bankNum,
        startSlot: info.startSlot,
        slotsInBank: info.slotsInBank,
        firstBank: info.firstBank,
        lastBank: info.lastBank
    };
}

export const copyAuxBank = (aux: AuxBank, target: Chassis): AuxBank => {
    return {
        parent: target,
        info: _dupBankInfo(aux.info),
        layout: { ...aux.layout },
        compsReqd: (aux.compsReqd ? [...aux.compsReqd] : undefined)
    };
}

export const addModule = (content: RackGroup, module: ChassisModule): boolean => {
    for (let chIdx = 0; chIdx < content.racks.length; chIdx++) {
        if (addModuleToChassis(content.racks[chIdx].chassis, module))
            return true;
    }
    return false;
}

export const getModuleSlotID = (module: ChassisModule): string => {
    if (module.parent) {
        return getSlotID(module.parent, module.slotIdx);
    }
    else {
        return SlotIDError.NoParentChassis;
    }
}

export const detachModule = (module: ChassisModule) => {
    // Get the parent chassis from the module.
    const existingChassis = module.parent;

    // If we can...
    if (existingChassis) {
        // Get the module in that chassis located in the slot
        // that the module thinks it resides in.
        const checkMod = getModuleInSlot(existingChassis, module.slotIdx);

        // That one SHOULD MATCH our module. If so...
        if (checkMod === module) {
            // Remove the module from the old parent's modules array.
            existingChassis.modules[module.slotIdx] = undefined;

            // Reset the module's slot location and parent.
            module.slotIdx = NO_SLOT;
            module.slotID = NO_SLOT;
            module.parent = undefined;

            // Remove any associated 'dummy' or parent
            // modules. Note: Dummy modules occupy slots
            // that a duplex (or greater) parent module
            // will occupy. Parent duplex and 'dummy' 
            // modules have the same ID.
            let idxMoreAssociated = existingChassis.modules.findIndex(x => x && x.id === module.id);
            while (idxMoreAssociated >= 0) {
                const dummy = existingChassis.modules[idxMoreAssociated];
                existingChassis.modules[idxMoreAssociated] = undefined;

                if (dummy) {
                    // Reset the module's slot location and parent.
                    dummy.slotIdx = NO_SLOT;
                    dummy.slotID = NO_SLOT;
                    dummy.parent = undefined;
                }

                idxMoreAssociated = existingChassis.modules.findIndex(x => x && x.id === module.id);
            }

            // Return. The module has been
            // successfully detached.
            return;
        }
    }

    // We should NEVER be asked to detach a module that
    // doesn't meet all of the above criteria. If we get
    // here, we'll just error out.
    throw new Error('Unexpected FAILURE in _detachModule!');
}

// Insert an existing module into a chassis
// at the specified slow number.
export const attachModule = (chassis: Chassis,
    module: ChassisModule, slotNum: number): boolean => {

    // Sanity tests...
    // Platform must match.
    if (chassis.platform !== module.platform) {
        return false;
    }

    // Slot requested must be valid for chassis.
    if (!isValidSlotNumber(chassis, slotNum)) {
        return false;
    }

    // The slot can't already be occupied.
    if (chassis.modules[slotNum]) {
        return false;
    }

    // The module itself should be fully DETACHED,
    // meaning that it has no parent and no slot location.
    if (module.parent || (module.slotIdx !== NO_SLOT)) {
        return false;
    }

    // If we're still here, attach it.
    chassis.modules[slotNum] = module;
    module.slotIdx = slotNum;
    module.slotID = slotNum;
    module.parent = chassis;

    // Success.
    return true;
}


export const getDeviceCategory = (deviceType: string): DeviceCategory => {
    switch (deviceType) {
        case DeviceType.Chassis:
            return DeviceCategory.Chassis;

        case DeviceType.PS:
            return DeviceCategory.PS;

        case DeviceType.BU:
            return DeviceCategory.BU;
            
        case DeviceType.Controller:
        case DeviceType.SafetyPartner:
        case DeviceType.CommModule:
        case DeviceType.IOModule:
        case DeviceType.Motion:
        case DeviceType.RedundMod:
        case DeviceType.SpecialtyMod:
        case DeviceType.IOExpansion:
            return DeviceCategory.Module;

        default:
            return DeviceCategory.Other;
    }
}

export const getDefaultImageSource = (imgName: string): string => {
    return DEFAULT_IMAGE_LOCATION + imgName;
}

//const _getDefaultChassisName = (chassis: Chassis): string => {
//    if (chassis.catNo.length) {
//        const lastCatDash = chassis.catNo.lastIndexOf('-');
//        const postDash = chassis.catNo.substring(lastCatDash + 1);
//        return ('Chassis_' + postDash);
//    }
//    else {
//        return 'Chassis';
//    }
//}

export const getChassisName = (chassis: Chassis): string => {
    if (!(chassis.name && chassis.name.length)) {
        chassis.name = getDefaultChassisName(chassis);
    }
    return getChassisNameSelection(chassis);
}

export const getProjectName = (project: ChassisProject): string => {
    const name = project.config.projectName;
    if (name && name.length) {
        return name;
    }

    return 'Project Configuration';
}

export const getStdSlotIdTagText = (chassis: Chassis, slotNum: number, toSlotNum = -1): string => {
    let innerText = getSlotID(chassis, slotNum);
    if (toSlotNum > slotNum) {
        innerText += '..' + getSlotID(chassis, toSlotNum);
    }
    return ('[' + innerText + ']');
}

export const getDevIdText = (device: SelectableDevice): string => {
    if (device.parent) {
        let locText = ''
        if (device.deviceType === DeviceType.PS) {
            locText = ' (PS) ';
        }
        else {
            const mod = device as ChassisModule;
            locText = ' (at slot ' + mod.slotID + ') ';
        }
        return device.catNo + locText + 'in chassis: ' + device.parent.name;
    }
    else {
        return 'UNPARENTED DEVICE: ' + device.catNo;
    }
}

export const getChassisIndex = (chassis: Chassis): number => {
    const rackGrp = chassis.parent;
    if (rackGrp) {
        for (let idx = 0; idx < rackGrp.racks.length; idx++) {
            if (rackGrp.racks[idx].chassis === chassis) {
                return idx;
            }
        }
    }
    throw new Error('getChassisIndex - unexpected Error!');
}

export const deleteChassis = (chassis: Chassis) => {
    const rackGrp = chassis.parent;
    if (rackGrp) {
        const idx = getChassisIndex(chassis);
        rackGrp.racks.splice(idx, 1);
        updateRackLayout(rackGrp);
        return;
    }
    throw new Error('deleteChassis - unexpected Error!');
}

export const getProjectFromChassis = (chassis: Chassis): ChassisProject | undefined => {
    return (chassis.parent !== undefined) ? chassis.parent.parent : undefined;
}

export const getRack = (chassis: Chassis): Rack | null => {
    if (chassis.parent) {
        for (let rackIdx = 0; rackIdx < chassis.parent.racks.length; rackIdx++) {
            const rack = chassis.parent.racks[rackIdx];
            if (rack.chassis === chassis) {
                return rack;
            }
        }
    }
    return null;
}

export const getRackOrgPt = (chassis: Chassis): Point => {
    const rack = getRack(chassis);
    return rack ? rack.ptOrg : { x: 0, y: 0 };
}

export const getRackLoc = (rack: Rack, includeLabel = false): LocAndSize => {
    const loc: LocAndSize = {
        x: rack.ptOrg.x,
        y: rack.ptOrg.y,
        width: rack.chassis.layout.size.width,
        height: rack.chassis.layout.size.height
    };
    if (includeLabel) {
        const spacingDtls = getStageSpacingDtls(ChassisLayoutKey);
        const htAdder = spacingDtls.chassisLblFontSize +
            spacingDtls.chassisToLblGap;
        loc.y -= htAdder;
        loc.height += htAdder;
    }
    return loc;
}

export const getChassisLoc = (chassis: Chassis, includeLabel = false): LocAndSize => {
    const rack = getRack(chassis);
    if (rack) {
        return getRackLoc(rack, includeLabel);
    }
    else {
        throw new Error('getRack FAILED to find chassis rack in getChassisLoc!');
    }
}

const _locInVertRange = (loc: LocAndSize, top: number, bottom: number): boolean => {
    if ((loc.y >= bottom) || ((loc.y + loc.height) <= top)) {
        return false;
    }
    return true;
}

// Tuning. If _viewBufPct is greater than 0, it is used
// to determine an EXTRA vertical space ABOVE and BELOW
// to be added to the viewPort provided in the getRacksInView
// function below. Our layout renders only those chassis
// in the range we return, and the idea is that if we render
// some outside (above and below) as well, those will come in
// smoother during scrolling.
const _viewBufPct = 1;

// Returns the idx range of racks that are at
// least partly inside the provided viewport in
// the vertical direction.
export const getRacksInView = (content: RackGroup,
    viewPort: NormRect): IdxRange => {

    // Init return object to
    // show nothing in range.
    const rng: IdxRange = {
        first: -1,
        last: -1
    }

    // See how many racks we have.
    const numRacks = content.racks.length;

    // If any...
    if (numRacks > 0) {

        // Determine what if any extra buffer space we should 
        // add above/below what we were provided. See note above.
        const maxBufDist = (_viewBufPct > 0) ? _viewBufPct * (viewPort.bottom - viewPort.top) : 0;

        // Given that, determine the top and bottom
        // y locations we want to check against.
        const chkTop = Math.max(0, viewPort.top - maxBufDist);
        const chkBtm = viewPort.bottom + maxBufDist;

        // We haven't found anything yet.
        let foundFirst = false;

        const spacingDtls = getStageSpacingDtls(ChassisLayoutKey);

        // Walk the racks. For each...
        for (let idx = 0; idx < numRacks; idx++) {

            // Get the rack.
            const rack = content.racks[idx];

            // Get its location, INCLUDING its label in its height.
            const loc = getRackLoc(rack, true);

            // Make height adjustments for space 
            // occupied if chassis is redundant.
            if (rack.chassis.redundant) {
                switch (getRedDepictOption()) {
                    case RedDepictOption.Below:
                        loc.height += (loc.height + spacingDtls.verticalSep);
                        break;

                    case RedDepictOption.Behind:
                        loc.height += 60;
                        break;

                    default:
                        break;
                }
            }

            // If this one is 'in view' (vertically)...
            if (_locInVertRange(loc, chkTop, chkBtm)) {

                // If we haven't already found any...
                if (!foundFirst) {
                    // This is the first.
                    rng.first = idx;
                    foundFirst = true;
                }

                // Set our new last.
                rng.last = idx;
            }
            else {
                // Not in view. If we already found any
                // that were, stop looking. No more will be.
                if (foundFirst) break;
            }
        }
    }

    // Return final result.
    return rng;
}


export const getChassisAndRackIndexById = (project: ChassisProject, chassisID: string): [Chassis | undefined, number] => {
    // Find the target chassis. Note: using find() instead
    // of forEach() since I believe find() exits the loop
    // when condition is satisfied.
    let chassis: Chassis | undefined = undefined;
    let idxTargetRack = -1;
    project.content.racks.find((rack, idx) => {
        if (rack.chassis.id === chassisID) {
            idxTargetRack = idx;
            chassis = rack.chassis;
            return true;
        }
        return false;
    });

    return [chassis, idxTargetRack];
}

export const getEnvRatingFromSpec = (xt: boolean, conformal: boolean): EnvRating => {
    if (xt) {
        return EnvRating.ExtTemperature;
    }
    else if (conformal) {
        return EnvRating.ConformalCoated;
    }
    else {
        return EnvRating.Standard;
    }
}

export const isWiringTypeSupported = (type: IOModuleWiring, supportedTypes: number): boolean => {

    if ((supportedTypes > SupportedWiringType.NA)) {
        let bitVal = 0x0000;

        switch (type) {
            case IOModuleWiring.Screw:
                bitVal = SupportedWiringType.Screw;
                break;

            case IOModuleWiring.SpringClamp:
                bitVal = SupportedWiringType.SpringClamp;
                break;

            case IOModuleWiring.IOReadyCable:
                bitVal = SupportedWiringType.IOReadyCable;
                break;

            case IOModuleWiring.WiringSys:
                bitVal = SupportedWiringType.WiringSys;
                break;

            default:
                throw new Error('Unexpected type request in isWiringTypeSupported!');
        }

        return ((bitVal & supportedTypes) !== 0);
    }
    else {
        return false;
    }
}

export const chassisHasController = (chassis: Chassis): boolean => {
    const [modSlots,] = getModuleSlotBreakdown(chassis.modules);
    if (modSlots.length > 0) {
        for (let modSlotsIdx = 0; modSlotsIdx < modSlots.length; modSlotsIdx++) {
            const module = chassis.modules[modSlots[modSlotsIdx]];
            //if (module && (module.deviceType === DeviceType.Controller)) {
            //    return true;
            if (module && (module.isController)) {
                return true;
            }
        }
    }
    return false;
}

export const chassisHasAnyModules = (chassis: Chassis): boolean => {
    for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {
        if (chassis.modules[slotIdx]) {
            return true;
        }
    }
    return false;
}

export const projectHasAnyModules = (project: ChassisProject): boolean => {
    for (let rackIdx = 0; rackIdx < project.content.racks.length; rackIdx++) {
        if (chassisHasAnyModules(project.content.racks[rackIdx].chassis)) {
            return true;
        }
    }
    return false;
}


export const chassisHasAnyEmptySlots = (chassis: Chassis): boolean => {
  let idxIOStart = 0;
  switch (chassis.platform) {
    case PlatformMicro:
      idxIOStart = chassis.modules.length;
      break;
    case PlatformFlex:
    case PlatformCpLX:
    case PlatformFlexHA:
      idxIOStart = 1;
      break;
    case PlatformCLX:
    default:
      break;
  }
  for (let slotIdx = idxIOStart; slotIdx < chassis.modules.length; slotIdx++) {
    if (!chassis.modules[slotIdx]) {
      return true;
    }
  }
  return false;
};

export const projectHasAnyEmptySlots = (project: ChassisProject): boolean => {

    for (let rackIdx = 0; rackIdx < project.content.racks.length; rackIdx++) {
        if (chassisHasAnyEmptySlots(project.content.racks[rackIdx].chassis)) {
            return true;
        }
    }
   return false;
}

export const isSlotFiller = (module: ChassisModule | undefined): boolean => {
    if (module) {
        return module.slotFiller;
    }
    return false;
}

export const chassisHasAnySlotFillers = (chassis: Chassis): boolean => {
    for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {
        if (isSlotFiller(chassis.modules[slotIdx])) {
            return true;
        }
    }
    return false;
}

export const projectHasAnySlotFillers = (project: ChassisProject): boolean => {
    for (let rackIdx = 0; rackIdx < project.content.racks.length; rackIdx++) {
        if (chassisHasAnySlotFillers(project.content.racks[rackIdx].chassis)) {
            return true;
        }
    }
    return false;
}

export const removeChassisSlotFillers = (chassis: Chassis) => {

    let anyRemoved = false;

    // Walk all of the slots in the chassis. For each...
    // 2024.4.25 Walk the module array in REVERSE. This
    // makes a BIG difference for Snap-Type platforms. 
    // Allow Slot 0 check even for Snap Platforms.
    for (let slotIdx = chassis.modules.length - 1; slotIdx >= 0; --slotIdx) {

        // If the slot contains a slot filler...
        if (isSlotFiller(chassis.modules[slotIdx])) {

            // Delete it
            deleteModuleAtSlot(chassis, slotIdx);
            anyRemoved = true;
        }
    }

    if (anyRemoved) {
        // snapCompactModuleArray() will just return
        // if platform is NOT a 'snap type' (CpLX/Flex).
        snapCompactModuleArray(chassis);
        chassisChanged(chassis);
    }
}

export const removeAllSlotFillers = (project: ChassisProject) => {
    for (let rackIdx = 0; rackIdx < project.content.racks.length; rackIdx++) {
        removeChassisSlotFillers(project.content.racks[rackIdx].chassis);
    }
}

export const addChassisSlotFillers = (chassis: Chassis) => {

    // If the chassis has any empty slots...
    if (chassisHasAnyEmptySlots(chassis)) {

        // Call a helper to get info about what
        // slot filler (if any) we should use.
        const [slotFillerCat, envMismatchOk] = getSlotFillerInfo(chassis);

        // If we get a catalog number...
        if (slotFillerCat.length) {

            let anyAdded = false;

            // Then walk all of the chassis slots. For each...
            for (let slotIdx = 0; slotIdx < chassis.modules.length; slotIdx++) {

                // If the slot is empty...
                if (!chassis.modules[slotIdx]) {

                    // Call a helper to add the slot filler for us.
                    addModuleAtSlot(chassis, slotFillerCat, slotIdx, envMismatchOk);
                    anyAdded = true;
                }
            }

            if (anyAdded) {
                chassisChanged(chassis);
            }
        }
    }
}

export const addSlotFillers = (project: ChassisProject) => {
    for (let rackIdx = 0; rackIdx < project.content.racks.length; rackIdx++) {
        addChassisSlotFillers(project.content.racks[rackIdx].chassis);
    }
}

export const resetModeInfo = (info: LayoutMode) => {
    info.type = LayoutModeType.Normal;
    info.origCat = '';
    info.origCC = false;
    info.origET = false;
    info.engInfo = undefined;
}

export const getAccessoryInfo = (device: GraphicalDevice, notFoundDesc?: string):
    BasicProdInfo[] | undefined => {

    if (device.accys && (device.accys.length > 0)) {
        const missingDesc = (notFoundDesc && notFoundDesc.length > 0)
            ? notFoundDesc
            : '<description not available>';

        const info = new Array<BasicProdInfo>();

        device.accys.forEach(accy => {
            const engInfo = getBasicEngInfoFor(device.platform, accy);
            info.push({
                catNo: accy,
                description: (engInfo && (engInfo.description.length > 0))
                    ? engInfo.description
                    : missingDesc
            });
        })

        return info;
    }
    return undefined;
}

export const getChassisStatus = (chassis: Chassis): LogMsgLevel => {
    return (chassis.statusLog ? chassis.statusLog.logStatus : LogMsgLevel.none);
}

export const getLabelCharsBoundary = (chassis: Chassis): number => {

    if (chassis.platform === PlatformCLX) {
        const numSlots = getNumSlots(chassis);
        if (chassis.extendedTemp) {
            switch (numSlots) {
                case 7:
                    return 48;

                default:
                    return 1000;
            }
        }
        else {
            switch (numSlots) {
                case 4:
                    return 30;

                case 7:
                    return 40;

                case 10:
                    return 48;

                default:
                    return 1000;
            }
        }
    }
    else {
        return 25;
    }
}

// Get the text used to label a chassis on the layout.
// Note: This should probably be platform-specific at some point.
export const getChassisLabelText = (chassis: Chassis, redSecondary: boolean, omitSlotCount = false): string => {
    let nameText = getChassisName(chassis);
    const microChassis = chassis as MicroChassis
    if (redSecondary) {
        nameText += '(R)';
    }

    if (!chassis.isPlaceholder) {
        nameText += ': ';
        nameText += chassis.catNo;
    }

    if (microChassis && microChassis.bu) {
        nameText += ': ';
        nameText += microChassis.bu.catNo;
    }

    if (!omitSlotCount) {
        const numSlots = getNumSlots(chassis);
        nameText += ('  (' + numSlots + ' slots)');
    }

    return nameText;
}

export const bringChassisIntoView = (chassis: Chassis) => {
    const chassisLoc = getChassisLoc(chassis);
    bringLocIntoView(ChassisLayoutKey, chassisLoc);
}

export const getEngineeringInfoFor = (dev: GraphicalDevice): EngInfoComponent | undefined => {
    return getEngInfoForComp(dev.platform, dev.catNo);
}

export const isDeviceCompatibleWithChassis = (device: GraphicalDevice, chassis: Chassis ): boolean => {
    if (chassis.platform === device.platform) {
        const chasInfo = getEngineeringInfoFor(chassis);
        const devInfo = getEngineeringInfoFor(device);
        if (chasInfo && devInfo) {
            return devInfo.isCompatibleWithEnv(chasInfo.envInfo.rating);
        }
        else {
            throw new Error('Missing eng info in isModuleCompatibleWithChassis!');
        }
    }
    return false;
}

export const getUpsizeCatalogNum = (platform: string, catNo: string): string => {
    const info = getEngInfoForComp(platform, catNo);
    const upsizeCat = info ? info.altUpsize : '';
    if (upsizeCat !== catNo) {
        return upsizeCat;
    }
    throw new Error('Invalid upsize data detected in getUpsizeCatalogNum!');
}

export const getPowerSuppliedBy = (ps: ChassisPowerSupply): PowerBreakdown => {
    if (ps) {
        const psInfo = getPowerSupplyEngInfo(ps.platform, ps.catNo);
        if (psInfo) {
            return { ...psInfo.powerAvail };
        }
    }
    return getEmptyPowerBreakdown();
}

export const getPowerConsumedBy = (module: ChassisModule): PowerBreakdown => {
    if (module) {
        const modInfo = getModuleEngInfo(module.platform, module.catNo);
        if (modInfo) {
            return { ...modInfo.powerUsed };
        }
    }
    return getEmptyPowerBreakdown();
}

// General version. Returns applicable power info for
// catNo provided IFF power applies at all.
// WARNING: Caller needs to understand CONTEXT of calling this
// function for a controller or comm module, and use the response
// CORRECTLY. For snap - type platforms like CpLX, first-slot
// modules(controllers and comm mods) are power SUPPLIERS, and
// our response should be treated as a start or ADD of power.
// For other platforms, like CLX, controllers and comm modules
// are pure CONSUMERS. When called for CLX, caller should treat
// our response for a controller or comm module as a consumed
// power that needs to be SUBTRACTED from what's available.
export const getPowerBreakdown = (platform: string, catNo: string): PowerBreakdown => {
    const engInfo = getEngInfoForComp(platform, catNo);
    if (engInfo) {

        // If a PS...
        if (engInfo.isPS) {
            // PS is a pure supplier. Return its powerAvail.
            const asPS = engInfo as EngInfoPowerSupply;
            return { ...asPS.powerAvail };
        }

        // If a controller...
        if (engInfo.isController) {

            // See if it has any power available.
            // First-slot controllers as used in CpLX
            // are sources of power and ALSO consume some.
            const asCtlr = engInfo as EngInfoController;
            return getNetPowerAvailable(asCtlr.powerAvail, asCtlr.powerUsed);
        }

        // First-slot comm modules in snap-type
        // platforms are sources of power as well.
        if (engInfo.isCommModule) {

            // See if this one has any power available.
            const asCommMod = engInfo as EngInfoCommModule;
            return getNetPowerAvailable(asCommMod.powerAvail, asCommMod.powerUsed);
        }

        // FPDs are sources for SA Power.
        if (engInfo.isFPD) {
            const asFPDMod = engInfo as EngInfoFPDModule;
            return getNetPowerAvailable(asFPDMod.powerAvail, asFPDMod.powerUsed);
        }

        // If we're still here and it's a module...
        if (engInfo.isModule) {
            // Treat as a consumer.
            const asMod = engInfo as EngInfoModule;
            return { ...asMod.powerUsed };
        }
    }
    return getEmptyPowerBreakdown();
}

export const getNetPowerAvailable = (available: PowerBreakdown, used: PowerBreakdown): PowerBreakdown => {
    // If we have available power...
    if (!isEmptyPower(available)) {
        // We'll return what's available minus what's used.
        const netAvail = subtractPowerBreakdown(available, used, false);
        return netAvail;
    }

    // Just a consumer.
    return used;
}

export const getDeviceDimensions = (dev: GraphicalDevice): Dimensions => {
    const engInfo = getEngInfoForComp(dev.platform, dev.catNo);
    if (engInfo) {
        return { ...engInfo.dimensions };
    }
    return {
        height: 0,
        width: 0,
        depth: 0
    }
}


export const getPlaceholderDeviceDimensions = (chassis: Chassis): Dimensions => {
    let totalWidth = 0;
    let totalHeight = 0;
    let totalDepth = 0;
    chassis.modules.forEach((mod) => {
      if (mod) {
        const chassisDims = getDeviceDimensions(mod);
        totalWidth += chassisDims.width;
        if (totalHeight < chassisDims.height) {
          totalHeight = chassisDims.height;
        }
        if (totalDepth < chassisDims.depth) {
          totalDepth = chassisDims.depth;
        }
      }
    });

    switch (chassis.platform) {
        case PlatformCpLX:
            totalWidth += 10.5;
            break;

        case PlatformFlex:
            totalWidth += 6;
            break;

        default:
            break;
    }

    const totalChassisDims = {'height': totalHeight, 'width': totalWidth, 'depth' : totalDepth}
    return totalChassisDims
}

export const updateAllChassis = (content: RackGroup) => {

    // If undo snapshots are currently suspended, so
    // are chassis updates. Just return in that case.
    if (areUndoSnapshotsSuspended()) {
        return;
    }

    // Give EACH chassis we have a chance to update
    // its own internal layout. Note that use false
    // for the updateParentLayout argument.
    content.racks.forEach(rack => {
        updateChassis(rack.chassis, false);
    })

    // After we're done, THEN we'll update
    // our content layout ONE TIME.
    updateRackLayout(content);
}

// Helper.
// In general, this function should be called when a 
// modification is made to a chassis that doesn't also
// end up with a full updateRackLayout.
export const chassisChanged = (chassis: Chassis | undefined) => {

    if (chassis) {
        // Increment the .bump property so that the associated
        // (memo-ized) ChassisComp component actually re-renders.
        // The prop is benign for all other concerns.
        chassis.bump += 1;

        // Mark our overall configuration as having been
        // modified. This is ONLY used for save/edit purposes.
        setConfigModified(true);
    }
    else {
        logger.error('chassisChanged called with undefined chassis!');
    }
}

export const getNumAuxBanks = (chassis: Chassis): number => {
    return (chassis.auxBanks)
        ? chassis.auxBanks.length
        : 0;
}

export const getNumBanks = (chassis: Chassis): number => {
    return getNumAuxBanks(chassis) + 1;
}

export const getNewBankInfo = (startSlot: number): BankInfo => {
    return {
        bankNum: -1,
        startSlot: startSlot,
        slotsInBank: -1,
        firstBank: false,
        lastBank: false
    };
}

// NOTE: Caller EXPECTS the requested aux to exist!
export const getAuxBank = (chassis: Chassis, auxIdx: number): AuxBank => {
    if (chassis.auxBanks) {
        const aux = chassis.auxBanks[auxIdx];
        if (aux) {
            return aux;
        }
    }
    throw new Error('Unexpected ERROR in _getAuxBank!');
}

const _getSingleBankInfo = (chassis: Chassis): BankInfo => {
    return {
        bankNum: 0,
        startSlot: 0,
        slotsInBank: chassis.modules.length,
        firstBank: true,
        lastBank: true
    }
}

export const getBankInfoFromSlot = (chassis: Chassis, slot: number): BankInfo => {
    const arrBankInfo = getInfoAllBanks(chassis);
    const bankInfo = arrBankInfo.find(info => slot >= info.startSlot && slot < (info.startSlot + info.slotsInBank));

    if (bankInfo)
        return bankInfo;

    throw new Error(`getBankInfoFromSlot(): invalid slot number (${slot})`);
}

export const getBankInfo = (chassis: Chassis, bank: number): BankInfo => {

    // See how many banks the chassis has.
    const numBanks = getNumBanks(chassis);

    // If the requested bank number is valid...
    if ((bank >= 0) && (bank < numBanks)) {

        // If bank 0 is requested.
        if (bank === 0) {

            // Do some sanity checking.
            // We should expect the chassis' primaryBankInfo
            // prop to have info available if we have more
            // than 1 bank. If we have just a single bank,
            // the property SHOULD NOT contain any info.
            const expInfo = (numBanks > 1);

            // Then look to see if there IS any data there.
            const infoAvail = chassis.primaryBankInfo ? true : false;

            // If our expectation matches...
            if (expInfo === infoAvail) {

                // Then return either what we have in the
                // chassis' prop, OR if we don't have anything,
                // default info for a single-bank chassis.
                return chassis.primaryBankInfo
                    ? chassis.primaryBankInfo
                    : _getSingleBankInfo(chassis);
            }
            else {
                // Unexpected: Sanity check FAILED.
                throw new Error('ERROR: Primary bank info mismatch in getBankInfo!');
            }
        }
        else {
            // Aux bank info requested. Get the relevant
            // aux and return its info.
            const aux = getAuxBank(chassis, bank - 1);
            return aux.info;
        }
    }
    throw new Error('ERROR: Invalid bank in getBankInfo!');
}

// TO DO: TRY TO REMOVE THIS??
export const getInfoAllBanks = (chassis: Chassis): BankInfo[] => {

    // Get the number of banks from the chassis.
    const numBanks = getNumBanks(chassis);

    // We should ALWAYS have AT LEAST 1. If so...
    if (numBanks > 0) {

        // Create a return array.
        const info = new Array<BankInfo>();

        // Add a BankInfo entry for each bank.
        for (let bank = 0; bank < numBanks; bank++) {
            info.push(getBankInfo(chassis, bank));
        }

        // Return the arry.
        return info;
    }

    throw new Error('Unexpected ERROR in getBankInfo!');
}

const _auxBankSortPred = (a: AuxBank, b: AuxBank): number => {
    if (a.info.startSlot < b.info.startSlot) {
        return -1;
    }
    else if (a.info.startSlot > b.info.startSlot) {
        return 1;
    }
    throw new Error('ERROR in _auxBankSortPred: Same startSlot!');
}

// Counts and Sorts any aux banks by startSlot.
// Returns the quantity of aux banks.
const _countAndSortAuxBanks = (chassis: Chassis): number => {

    // See how many aux banks we have.
    const numAuxes = getNumAuxBanks(chassis);

    // If more than 1...
    if (numAuxes > 1) {

        // Sort them based on starting slot.
        if (chassis.auxBanks) {
            chassis.auxBanks.sort(_auxBankSortPred);
        }
        else {
            throw new Error('Unexpected ERROR in _countAndSortAuxBanks!');
        }
    }

    // Return the qty.
    return numAuxes;
}

export const updateBanks = (chassis: Chassis) => {

    // Count and sort any aux banks found.
    const numAuxes = _countAndSortAuxBanks(chassis);

    // If any
    if (numAuxes > 0) {

        // Start a working slots number at
        // our chassis' full count.
        let remainingSlots = chassis.modules.length;

        // Walk the auxes from last to first.
        // For each.
        for (let bankNum = numAuxes; bankNum > 0; bankNum--) {

            // Get a ref to the associated BankInfo
            // object from the auxBank.
            const info = getBankInfo(chassis, bankNum);

            // Make sure bank info props are up to date.
            info.bankNum = bankNum;
            info.slotsInBank = remainingSlots - info.startSlot;
            info.firstBank = false;
            info.lastBank = (bankNum === numAuxes);

            // Adjust rem slots down by how many
            // were used on this aux bank.
            remainingSlots -= info.slotsInBank;
        }

        // Since we DO have multiple banks,
        // add bank info for the primary bank
        // to the chassis itself.
        chassis.primaryBankInfo = {
            bankNum: 0,
            startSlot: 0,
            slotsInBank: remainingSlots,
            firstBank: true,
            lastBank: false
        }
    }
    else {
        // No auxes. Make sure any/all bank-
        // related chassis data is removed.
        chassis.auxBanks = undefined;
        chassis.primaryBankInfo = undefined;
    }
}

// Given a slot and bank info, returns the local
// offset of the slot within the bank itself.
// If the slot isn't part of the bank, returns -1.
const _getLocalSlotOffset = (slot: number, bankInfo: BankInfo): number => {
    if ((slot >= bankInfo.startSlot) &&
        (slot < (bankInfo.startSlot + bankInfo.slotsInBank))) {
        return (slot - bankInfo.startSlot);
    }
    else {
        return -1;
    }
}

// Returns the bank number and local offset of
// the provided slot number inside that bank.
export const getSlotBankInfo = (chassis: Chassis, slotNum: number):
    [bankNum: number, bankSlotOffset: number] => {

    // Sanity check that the slot is valid. If so...
    if (isValidSlotNumber(chassis, slotNum)) {

        // See how many aux banks are on the chassis.
        const numAuxes = getNumBanks(chassis) - 1;

        // Walk any we have. For each...
        for (let auxIdx = 0; auxIdx < numAuxes; auxIdx++) {

            // Get the aux.
            const aux = getAuxBank(chassis, auxIdx);

            // Get the 'local' offset of the slot
            // for the given info. If it's not on
            // the aux bank, we get a -1 back.
            const locOffset = _getLocalSlotOffset(slotNum, aux.info);

            // If the slot is on the bank, return
            // the bank number and the local offset.
            if (locOffset >= 0) {
                return [auxIdx + 1, locOffset];
            }
        }
        // If we're still here, the slot
        // is on the primary bank.
        return [0, slotNum];
    }
    throw new Error('Unexpected Error in getSlotBankInfo!');
}

export const getAuxBankStartSlots = (chassis: Chassis): number[] => {

    const startSlots = new Array<number>();

    // See how many aux banks are on the chassis.
    const numAuxes = getNumBanks(chassis) - 1;

    // Walk any we have. For each...
    for (let auxIdx = 0; auxIdx < numAuxes; auxIdx++) {

        // Get the aux.
        const aux = getAuxBank(chassis, auxIdx);

        // Add its start slot to our array.
        startSlots.push(aux.info.startSlot);
    }

    // Return results.
    return startSlots;
}
