import { getChassisProperties, getChassisRendInfo } from "../implementation/ImplGeneral";
import {
    PlatformCLX,
    PlatformCpLX,
    PlatformFlex,
    PlatformFlexHA,
    PlatformMicro
} from "../platforms/PlatformConstants";
import { Chassis } from "../types/ProjectTypes";
import { LocAndSize, Size } from "../types/SizeAndPosTypes";
import { BOMItemExch, ChassisPropertiesExch } from "../userProject/UserProjectTypes";


export interface RendBorder {
    stroke: string;
    strokeWidth?: number;
    strokeScaleEnabled?: boolean;
}

export interface RendPortion {
    loc: LocAndSize;
    image: string;
    border?: RendBorder;
}

export interface RendInfo {
    size: Size;
    simpleImage?: string;
    els?: RendPortion[];
}

export interface DevMetadata {
    rendInfo?: RendInfo[];
    ctlrCommsSpt?: unknown;
    remCommsReqd?: unknown;
    chassisDetails?: ChassisPropertiesExch;
}

export interface ChassisFmtForMdTp {
    guid: string;
    devMetadata: DevMetadata;
}

export const getSysDsgDefChassisImg = (platform: string): string | undefined => {
    const path = 'CSA/Assets/SysDesign/';
    switch (platform) {
        case PlatformCLX:
            return path + '1756DefChassis.png';
        case PlatformCpLX:
            return path + '5069DefChassis.png';
        case PlatformFlex:
            return path + '5094DefChassis.png';
        case PlatformFlexHA:
            return path + '5015DefChassis.png';
        case PlatformMicro:
            return path + '2080DefChassis.png';
        default:
            break;
    }

    return undefined;
}

export const getChassisMetadata = (chassis: Chassis, red: boolean, chassisBomItExHdr?: BOMItemExch): string => {
    const devMd: DevMetadata = {
        rendInfo: getChassisRendInfo(chassis),
    }

    if (chassis.ctlrCommsSpt) {
        devMd.ctlrCommsSpt = { ...chassis.ctlrCommsSpt };
    }

    if (chassis.remCommsReqd) {
        devMd.remCommsReqd = { ...chassis.remCommsReqd };
    }

    devMd.chassisDetails = getChassisProperties(chassis, chassisBomItExHdr);

    const chasId = red
        ? chassis.id + '(R)'
        : chassis.id;

    const dataForTp: ChassisFmtForMdTp = {
        guid: chasId,
        devMetadata: devMd
    }

    const tp = JSON.stringify(dataForTp);
    return tp;
}
