import { IOEntryModeEnum, SettingGroupInfo, SettingValue } from "../types/SettingsTypes";
import { LocAndSize, Point, Size } from "./SizeAndPosTypes";
import { getNewInstanceId, InstanceId } from "../util/InstanceIdHelp";
import { ProjectLog } from "../util/ProjectLog";
import { CtlrChassisCommsSpt, RemIOChasCommsReqd } from "../model/CommDetails";
import { IOModuleSelection } from "./IOModuleTypes";
import { createNewPointEntrySectionInfo, PointEntrySectionInfo } from "./IOPointEntryTypes";
import { StatusLevel } from "./MessageTypes";
import { PSInputVoltage } from "./PowerTypes";


// Slot number used to indicate 'N/A', or
// when we have no slot number applicable.
export const NO_SLOT = -1;
export const NO_SLOTID = '[-1]';

export enum ModuleSlotRestriction {
    FirstSlotOnly = 'FirstSlotOnly',
    NotFirstSlot = 'NotFirstSlot',
    None = 'None',
    SlotWithPlugin = 'SlotWithPlugin',
    SlotWithBU = 'SlotWithBU',
    SlotWithIOexpansion = 'SlotWithIOexpansion'
}

export const DeviceType = {
    Chassis: 'Chassis',
    PS: 'Power Supply',
    BU: 'Base Unit',
    Controller: 'Controller',
    IOExpansion: 'Expansion I/O',
    PlugIn: 'Plug-In',
    SafetyPartner: 'Safety Partner Controller',
    CommModule: 'Communication Module',
    IOModule: 'I/O Module',
    Motion: 'Motion Module',
    RedundMod: 'Redundancy Module',
    SpecialtyMod: 'Specialty Module',
    FPD: 'Field Power Distributor',
    SlotFiller: 'Slot Filler',
    BankExpansionKit: 'Bank Expansion Kit',
    BankExpDevice: 'Bank Expansion Device',
    MountingPlate: 'Mounting Plate',
    Accessory: 'Accessory',
    Cable: 'Cable',
    Other: 'Other'
}

export const isKnownDeviceType = (type: string): boolean => {
    switch (type) {
        case DeviceType.Chassis:
        case DeviceType.PS:
        case DeviceType.PlugIn:
        case DeviceType.Controller:
        case DeviceType.SafetyPartner:
        case DeviceType.CommModule:
        case DeviceType.IOModule:
        case DeviceType.Motion:
        case DeviceType.RedundMod:
        case DeviceType.SpecialtyMod:
        case DeviceType.FPD:
        case DeviceType.SlotFiller:
        case DeviceType.BankExpansionKit:
        case DeviceType.BankExpDevice: // Special purpose - not found in Eng data
        case DeviceType.MountingPlate:
        case DeviceType.Accessory:
        case DeviceType.Cable:
        case DeviceType.IOExpansion:
        case DeviceType.Other:
            return true;

        default:
            return false;
    }
}

export interface BasicProdInfo {
    catNo: string;
    description: string;
}

export interface MicroForCopy {
    catNo: string;
    slotNumber: number;
}

export interface SlotModulePair { // Used for Chassis Config.
    slot: number;
    module: ChassisModule;
}

export interface MicroInputType{
    digitalInput: number,
    digitalOutput: number,
    analogInput: number,
    analogOutput: number,
    relayOutput:number,
    rtd:number,
    therm:number,
    hsc:number
}


export interface MicroparamDigitalinputOutputMax{
    catalogNumber: string,
    ioPoints: number,
    envRating?: string;
    ioType?: number;
    isCombo?: boolean;
    vltgMask?:number;
}


export interface MicroparamDigitalinputOutput   extends MicroparamDigitalinputOutputMax {
    idx:number
    deviceType?:string;
}

export enum EnvRating {
    Standard = 'Standard',
    ConformalCoated = 'Conformal Coated',
    ExtTemperature = 'Extended Temperature',
}

export enum DeviceCategory {
    Chassis = 'Chassis',
    PS = 'PS',
    BU = 'BU',
    Module = 'Module',
    Other = 'Other'
}

export enum DeviceCompatibility {
    Compatible = 'Compatible',
    NotCompatible = 'NotCompatible',
    CompatibleWithSwap = 'CompatibleWithSwap'
}

export interface LocAttrPersist {
    id: string;
    platform: string;
    industryID: string;
    installLocID: string;
    ioEntryMode: IOEntryModeEnum;
    settings: SettingValue[];
    ioSelections: IOModuleSelection[];
}

export interface ChassisProjectConfig {
    projectName?: string;
    version?: string;

    // These will be duped in the 
    // Location Attributes.
    installLocID: string;
    industryID: string;

    // This will be duped in Loc Attrs. 
    // Mode can be applied to ANY platform.
    IOEntryMode: IOEntryModeEnum;

    currLocAttrID: string;
    arrLocAttr: LocAttrPersist[];
}


export const SlotIDError = {
    InvalidSlotNum: '?V',
    NoParentChassis: '?C',
    BadPlatform: '?P'
};

// Used when determining what a given point is over.
export enum ChassisElement {
    // Not on chassis
    None = 'None',       

    // Power supply
    PS = 'PS',

    // External Power Supply (e.g. FlexHA SA PSU)
    ExtPS = 'ExtPS',

    // Micro Base Unit
    BU = 'BU',

    // Chassis slot
    Slot = 'Slot',

    // Bank expansion component
    BankExp = 'BankExp',

    // Somewhere on the chassis, but not
    // specifically a slot or the PS (like
    // a separator or right end piece).
    Chassis = 'Chassis',

    // On the redundant depiction of
    // a redundant chassis.
    RedChassis = 'RedChassis'
}

export enum ChassisRendType {
    Standard = 'Standard',
    RedPrimary = 'RedPrimary',
    RedSecondary = 'RedSecondary'
}

export enum ModuleDragStatus {
    NA = 'NA',
    Moving = 'Moving',
    Copying = 'Copying'
}

export enum ResultStatus {
    Error = 'Error',
    Success = 'Success',
    TestOnlyMsg = 'TestOnlyMsg'
}


export enum LoadStatus {
    Startup = 'Startup',
    Pending = 'Pending',
    Ready = 'Ready',
    Error = 'Error'
}


// NOTE: The values here are used for display purposes
// as well, so use caution before changing any of them.
export enum IOModuleWiring {
    Screw = 'Screw',
    SpringClamp = 'Spring-Clamp',
    IOReadyCable = 'I/O Ready Cable',
    WiringSys = 'Wiring System w/ Pre-wired Cable'
}

export const SupportedWiringType = {
    NA:           0x0000,
    Screw:        0x0001,
    SpringClamp:  0x0002,
    IOReadyCable: 0x0004,
    WiringSys:    0x0008
};

export enum UsageLevelStatus {
    OK = 'OK',
    Warning = 'Warning',
    Error = 'Error'
}



export interface UsageItemProgbarReplacement {
    replTipText: string;
    replTipStatus: StatusLevel;
    repLabel: string;
}

export enum ChassisCfgGrpCategory {
    Hidden = 'Hidden',
    Chassis = 'Chassis',
    Power = 'Power',
}

export interface GraphicalDevice {
    id: InstanceId;
    platform: string;
    deviceType: string; // DeviceType values
    catNo: string;
    description: string;
    isPlaceholder: boolean;
    imgSrc: string;
    extendedTemp: boolean;
    conformal: boolean;
    accysPossible: boolean;
    accys?: string[];
}

export interface GraphicalDevicePlugin {
    id: InstanceId;
    platform?: string;
    deviceType?: string; // DeviceType values
    catNo?: string;
    description?: string;
    isPlaceholder?: boolean;
    imgSrc: string;
    extendedTemp?: boolean;
    conformal?: boolean;
    accysPossible?: boolean;
    accys?: string[];
}


export interface SelectableDevice extends GraphicalDevice {
    category: DeviceCategory;
    imgSize: Size;
    selected: boolean;
    movable: boolean;
    dragStatus: ModuleDragStatus; // always NA for non-movable devices.
    parent?: Chassis;
}

export interface SelectableDevicePlugin extends GraphicalDevicePlugin {
    category: DeviceCategory;
    imgSize: Size;
    selected: boolean;
    movable: boolean;
    dragStatus: ModuleDragStatus; // always NA for non-movable devices.
    parent?:undefined;
}

export interface ChassisPowerSupply extends SelectableDevice {
    redundant: boolean;
    loc: LocAndSize;
    inputVoltage: PSInputVoltage;
}

export interface FlexHASAPowerSupply extends ChassisPowerSupply {
    ioBaseIndex: number;
    autoAdded: boolean;
}

export interface ChassisBaseUnit extends SelectableDevice {
    loc: LocAndSize;
    initialWidth:number;
}

export interface ChassisModule extends SelectableDevice {
    type?: string;
    slotIdx: number;
    slotID: number;
    slotsUsed: number;
    isController: boolean;
    isComm: boolean;
    isConnClient: boolean;
    spclLocalConns: number;
    slotFiller: boolean;
    isFPD: boolean;
    isInterconnect: boolean;
    isBankExp: boolean;
    //okInAnyChassisType: boolean;
}

export interface BankExpModule extends ChassisModule {
    bankNum: number;
    onRight: boolean;
}

export interface ChassisLayoutInfo {
    platform: string;
    size: Size;
    numFPDs: number;
    extendedTemp: boolean;
    conformal: boolean;
    slotLocs: LocAndSize[];
    rightCapImgSrc: string;
    rightCapLoc: LocAndSize;
}

export interface BankInfo {
    bankNum: number; // 0 for primary
    startSlot: number;
    slotsInBank: number;
    firstBank: boolean;
    lastBank: boolean;
}

export interface AuxBank {
    parent: Chassis;
    info: BankInfo;
    layout: ChassisLayoutInfo;
    compsReqd?: string[];
}

// WCS - NOTE: The .bump (formerly .renderBump) property was added
// to so that it could be passed as an additional prop to our
// ChassisComp component. That component is now memo-ized, and as
// such, would NOT re-render for changes WITHIN the chassis that
// did NOT cause a full updateRackLayout. A change in the .bump
// prop now causes the specific chassis to be re-rendered.
// IN GENERAL, code SHOULD NO LONGER change this property
// directly. Instead, they should call the chassisChanged
// function (in ChassisProject). That function not only bumps
// the prop value, but ALSO call calls setConfigModified(true).
// The modified status of a configuration is used for saves/edits.
export interface Chassis extends GraphicalDevice {
    name?: string;
    bump: number;
    dragTarget: boolean;
    xSlotWidth: number;

    // NOTE: When a chassis has multiple banks, the
    // layout is used for the primary bank itself,
    // and doesn't include things like slotLocs for
    // the aux banks. Each of those has its OWN layout.
    layout: ChassisLayoutInfo;

    ps?: ChassisPowerSupply;
    selected: boolean;
    parent?: RackGroup;
    modules: Array<ChassisModule | undefined >;
    redundant: boolean;
    defaultIOModWiring: IOModuleWiring;
    statusLog?: ProjectLog;
    ctlrCommsSpt?: CtlrChassisCommsSpt;
    remCommsReqd?: RemIOChasCommsReqd;
    isPower?: boolean;

    // Note: The following two props are expected
    // to be undefined UNLESS the chassis both
    // supports aux banks AND has any of them.
    auxBanks?: AuxBank[];
    primaryBankInfo?: BankInfo;
}

export interface MicroChassis extends Chassis {
    pluginModules?:Array<ChassisModule  | undefined>;
    bu?: ChassisBaseUnit | undefined;
    buCatnumber?: string,
    isBU?: boolean;
    isIoSeperate?: boolean;
    ioExpansionModule?:Array<ChassisModule  | undefined>;
    defaultPowerSupplyValue?: PSInputVoltage;
    totalModules?: number;
}

export interface FlexHAChassis extends Chassis {
    saPSU: FlexHASAPowerSupply[];
}

export interface Rack {
    ptOrg: Point;
    chassis: Chassis;
}

export interface RackGroup {
    racks: Rack[];
    totalExtent: Point;
    parent?: ChassisProject;
    newContent: boolean;
    anyRedundant: boolean;
    statusLog: ProjectLog;
}

export interface ChassisProject {
    id: InstanceId;
    config: ChassisProjectConfig;
    content: RackGroup;
}


// Populated by Product Selection and used
// to generate hardware configured in the 
// Design Page. May need to be platform specfic(?).
export interface HardwareBuilder {
    platform: string;
    remoteIOOnly?: boolean;
    catChassis?: string;
    catControllerChassis?: string;
    //isMicrovalid used for checking hardwaregen exceed controller limit in micro 800
    isMicrovalid?: boolean;
    // Storing catalogue number of controller that used for micro 800 algorithm
    microCatController?: string[];
    // Storing modules and id  that used for micro 800 algorithm
    microCatIO?: MicroparamDigitalinputOutput[];
    // Storing reserveplugin for micro 800
    microPlugin?: string;
    numChassisSlot?: number;
    numCtrlChassisSlot?: number;
    ExpansionIO?: number;
    spareCapacity?: number;
    Plugin?: number;
    catPowerSupply?: string;
    catPwrSupCtrlChassis?: string;
    catController?: string;
    catControllerPartner?: string[];
    catScanner?: string;
    redCtrlChassis?: boolean;
    catCtrlPowerSupplier?: string;
    catRemotePowerSupplier?: string;
    catIOModules: string[];
    catSlotFiller?: string; // Note: If set, we add slot fillers. 
    mapErrMessages: Map<string, string>;
    ctrlDedicatedChassis: boolean;
    ctrlChassisNeedsScanner: boolean;
    ioModuleSelections: IOModuleSelection[]; // Used by the settings
    ioModuleCount: number;
    initialized: boolean;
    envRating: EnvRating;
}

export const createInitialHardwareBuilder = (platform: string): HardwareBuilder => {
    return {
        platform: platform,
        catIOModules: [],
        mapErrMessages: new Map<string, string>(),
        ioModuleCount: 0,
        ioModuleSelections: [],
        ctrlDedicatedChassis: false,
        ctrlChassisNeedsScanner: false,
        initialized: false,
        envRating: EnvRating.Standard,
    };
}


////// LOCATION ATTRIBUTE INFO (i.e. product of parsing Guided Selection JSON) /////////
// Creating/Loading GS info into LocAttributeInfo.								////////

// Interface contains Everything from parsed GS Json.
// In anticipation of more platforms and possibly
// multiple 'Design Locations', which could mix
// different platforms, we will package everything
// we need in this interface to create Attribute UI
// and Hardware.

// During non-Dev startup, industryID & installLocationID
// will be empty strings and platform will default to CLX.
// The Guided Selection will not be loaded until the user
// has selected both Industry and Install Loc. Note: Default
// name will have '*Platform*' replaced by Family Number and
// a '00X' number added to the end (ie 'Location_1756_001').
export const defaultLocationName = 'Location_*Platform*_';
export interface LocAttributeInfo {
    id: string;
    name: string;
    platform: string;
    ioEntryMode: IOEntryModeEnum;
    industryID: string;
    installLocationID: string;
    attrGroups: SettingGroupInfo[];
    arrAttributeNameToValue: SettingValue[];
    hardware: HardwareBuilder;
    pointEntrySection: PointEntrySectionInfo;
    defaultGuidedSel?: boolean;
    defaultProdSel?: boolean;
    defaultProdData?: boolean;
    guidedSelCacheKey?: string;
    hasRuleViolation?: boolean;
}

export const createNewLocAttributeInfo = (
    platform: string,
    industryID: string,
    installLocationID: string, // Country Code
    ioEntryMode: IOEntryModeEnum): LocAttributeInfo => {

    return {
        id: getNewInstanceId(),
        name: defaultLocationName,
        platform: platform,
        industryID: industryID,
        installLocationID: installLocationID, // Country Code
        ioEntryMode: ioEntryMode,
        attrGroups: [],
        arrAttributeNameToValue: [],
        hardware: createInitialHardwareBuilder(platform),
        pointEntrySection: createNewPointEntrySectionInfo(),
    };
}

export type onCfgLocAttrCreatedCallback = (success: boolean, cfgAttr: LocAttributeInfo) => void;

export interface locAttrStartupDetails {
    idLocAttr: string;
    platform: string;
    industryID: string;
    installLocID: string;
}
